import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsStepShortGoalViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_step_short_goal';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);
}
