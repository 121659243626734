





















import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsStepShortGoalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-step-short-goal-view-model';

@Component({ name: 'FlagshipGoalsStepShortGoal' })
export default class FlagshipGoalsStepShortGoal extends Vue {
  step_short_goal_model = Vue.observable(new FlagshipGoalsStepShortGoalViewModel());
}
